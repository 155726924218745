<template>
    <div class="content_container customer_terms">
        <div class="content">
            <div class="banner">
                <h1>약관 및 규정</h1>
            </div>
            <section class="terms">
				<ul class="terms_menu">
					<li>
						<router-link to="/customer/termscredit">여신거래 약관</router-link>
					</li>
					<li class="current">
						<router-link to="/customer/termscustomer">고객권리 안내문</router-link>
					</li>
				</ul>
				<div class="text_box" id=""> 
					<div class="csfont2 title" style="color:#000;">고객관리안내문</div>

							<br><br>
							<div class="csfont1">1. 금융서비스 이용 범위</div>
							<div class="csfont2" style="padding-left:15px">
							이 약관에서 사용하는 용어들의 정의는 다음 각호와 같습니다.
							<br>가. 고객의 개인신용정보는 금융거래의 설정·유지여부 판단 목적 및 고객이  동의한 목적으로만 이용됩니다.
							<br>나. 고객은 영업장·인터넷 등 다양한 채널을 통해 금융거래를 체결하거나  금융서비스를 제공 받는 과정에서 1)금융회사가 본인의 개인신용정보(이  하‘ 본인정보’)를 제휴·부가서비스 등을 위해 제휴회사 등에 제공하는  것 및 2)당해 금융회사가 금융상품 소개 및 구매권유(이하‘ 마케팅’) 목적  으로 이용하는 것에 대해 동의하지 않는 경우에도 금융거래를 체결하거  나 금융서비스를 이용하실 수 있습니다. 다만, 이러한 동의를 하지 않으  신 경우에는 제휴·부가서비스 및 신상품·서비스 등을 제공받지 못할  수도 있습니다.
							</div>

							<br><br>

							<div class="csfont1">2. 신용정보의 이용 및 보호에 관한 법률」상의  고객 권리</div>
							<div class="csfont2" style="padding-left:15px">
							고객은「신용정보의 이용 및 보호에 관한 법률」및「신용정보업감독규정」등에  따라 아래의 권리가 부여되어 있습니다. 동 권리의 세부내용에 대해서는 금융  감독원 홈페이지(www.fss.or.kr)에 게시되어 있으며, 동 권리를 행사하고  자 하는 고객은 당사 고객정보 보호담당자 또는 각 영업점 앞으로 신청하여 주  시기 바랍니다.
							<br>&nbsp;- 본인정보의 제3자 제공사실 통보요구 : 고객은「신용정보의 이용 및 보호에  관한 법률」제35조에 따라 금융회사가 본인정보를 전국은행연합회, 신용조  회회사, 타금융기관 등 제3자에게 제공한 경우 제공한 본인정보의 주요내  용 등을 알려주도록 금융회사에 요구할 수 있습니다.
							<br>&nbsp;금융거래 거절 근거 신용정보 고지 요구 : 고객은「신용정보의 이용 및 보호  에 관한 법률」제36조에 따라 금융회사가 전국은행연합회, 신용조회회사  등으로부터 제공받은 연체정보 등에 근거하여 금융거래를 거절·중지하는  경우에는 그 거절·중지의 근거가 된 신용정보, 동 정보를 제공한 기관의  명칭·주소·연락처 등을 고지해 줄 것을 금융회사에 요구할 수 있습니다.
							<br>&nbsp;- 본인정보의 제3자 제공 및 마케팅 목적의 전화 등의 중단요구 : 고객은「신  용정보의 이용 및 보호에 관한 법률」제37조에 따라 신청시 동의를 한 경우  에도 본인정보를 제3자에게 제공하는 것 및 당해 금융회사가 마케팅 목적으  로 본인에게 연락하는 것을 전체 또는 사안별로 중단 시킬 수 있습니다. (다 만, 고객의 신용도 등을 평가하기 위해 전국은행연합회 또는 신용조회회사  등에 제공하는 것에 대해서는 중단시킬 수 없습니다.)
							<br>
							&nbsp;☞ 신청자 제한 : 동의철회권의 경우, 신규거래고객은 계약 체결일로부터 3개  월간은 신청할 수 없습니다.
							<br>&nbsp;- 본인정보의 열람 및 정정 요구 : 고객은「신용정보의 이용 및 보호에 관한  법률」제38조에 따라 전국은행연합회, 신용조회회사, 금융회사 등이 보유한  본인정보에 대해 열람 청구가 가능하며, 본인정보가 사실과 다를 경우에는  이의 정정 및 삭제를 요청할 수 있으며, 그 처리결과에 이의가 있는 경우에  는 금융위원회에 시정을 요청할 수 있습니다.
							<br>&nbsp;- 본인정보의 무료 열람 요구 : 고객은「신용정보의 이용 및 보호에 관한 법  률」제39조에 따라 본인정보를 신용조회회사를 통하여 연간 일정 범위 내에  서 무료로 열람할 수 있습니다. 자세한 사항은 각 신용조회회사에 문의하시  기 바랍니다.
							<br>
								1) 서울신용평가정보㈜ 전화 : 02) 3445-5000 홈페이지 :<a href="http://www.sci.co.kr" target="_blank">www.sci.co.kr</a>
								<br>
								2) NICE신용평가정보㈜전화 : 02) 3771-1000 홈페이지: <a href="http://www.mycredit.co.kr" target="_blank">www.mycredit.co.kr</a>
								<br>
								3) 한국개인신용㈜ 전화 : 02) 708-6000 홈페이지 : <a href="http://www.kcb4u.com" target="_blank">www.kcb4u.com</a>
								<br>
								
							</div>

							<br><br>

							<div class="csfont1">3. 신청방법</div>
							<div class="csfont2" style="padding-left:15px">
							&nbsp;- 당사 : 1600-5435 (홈페이지 : <a href="http://www.dongwhacapital.com/" target="_blank">www.dongwhacapital.com</a>)
								<br>&nbsp;- 여신금융협회: 02)2011-0700(홈페이지 : <a href="http://www.crefia.or.kr" target="_blank">www.crefia.or.kr</a>)
							</div>

							<br><br>

							<div class="csfont1">4. 위의 권리행사와 관련하여 애로사항이 있으신 경우 아래의 담당자 앞으로  연락하여 주시기 바랍니다.</div>
							<div class="csfont2" style="padding-left:15px">
							<br>&nbsp;- 당사 고객정보관리·보호인 연락처 주소 : (22813) 인천 서구 염곡로 133 133 Tel : 032-265-3214
								<br>&nbsp;- 여신금융협회 고객정보관리·보호인 연락처 주소  (100-180) 서울특별시 중구 다동길 43 한외빌딩 13층  Tel: 02) 2011-0728, 0745
								<br>&nbsp;- 금융감독원 고객정보관리·보호인 연락처 주소 : (150-743) 서울특별시  영등포구 여의대로 38 Tel : 02) 3786-8381, 8404
								
							</div>

				</div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
	name: 'TermsCustomer',
}
</script>

